import firebase from "firebase/app"
import "firebase/database"

// Firebase web config
const config = {
  apiKey: process.env.API_KEY,
  apiUrl: process.env.API_URL,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DB_URL,
  dynamicLink: process.env.DYNAMIC_LINK,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.SENDER_ID,
}

let instance = null

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance
    instance = firebase.initializeApp(config)
    return instance
  }

  return null
}
