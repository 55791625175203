import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import moment from "moment"
import React, { useState } from "react"
import SwipeableViews from "react-swipeable-views"
import * as styles from "./Swipeable.module.css"

const round = amount => Math.round(amount * 100) / 100

const Swipeable = ({ images, distanceSum = 0, durationSum = 0 }) => {
  const [index, setIndex] = useState(0)
  const distanceInKm = distanceSum / 1000
  const distance = round(distanceInKm)
  const intl = useIntl()

  const elapsedHours = moment.duration(durationSum, "seconds").asHours()

  const swipeables = [
    {
      image: images.car,
      title: intl.formatMessage({ id: "co2.title" }),
      amount: new Intl.NumberFormat("hu-HU").format(
        round((130 * distance) / 1000)
      ),
      unit: intl.formatMessage({ id: "co2.unit" }),
    },
    {
      image: images.gasoline,
      title: intl.formatMessage({ id: "gasoline.title" }),
      amount: new Intl.NumberFormat("hu-HU").format(round(0.08 * distance)),
      unit: intl.formatMessage({ id: "gasoline.unit" }),
    },
    {
      image: images.energy,
      title: intl.formatMessage({ id: "energy.title" }),
      amount: new Intl.NumberFormat("hu-HU").format(round(0.1 * elapsedHours)),
      unit: intl.formatMessage({ id: "energy.unit" }),
    },
  ]

  return (
    <div className={styles.swipe}>
      <SwipeableViews index={index} onChangeIndex={setIndex}>
        {swipeables.map((swipeable, i) => (
          <div className={styles.slide} key={`slide_${i}`}>
            <div className={styles.img}>
              <Img fixed={swipeable.image} />
            </div>
            <div className={styles.label}>
              <span>{swipeable.title}</span>
              <span>
                <span className={styles.amount}>{swipeable.amount}</span>{" "}
                {swipeable.unit}
              </span>
            </div>
          </div>
        ))}
      </SwipeableViews>
      <div className={styles.circles}>
        {swipeables.map((_, i) => (
          <button
            key={`btn_${i}`}
            onClick={() => setIndex(i)}
            className={`${styles.circle} ${i === index && styles.active}`}
          ></button>
        ))}
      </div>
    </div>
  )
}

export default Swipeable
