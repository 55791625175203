import { useIntl } from "gatsby-plugin-intl"
import moment from "moment"
import React, { useEffect, useState } from "react"
import * as styles from "./TimeLeft.module.css"

const TimeLeft = ({ competition }) => {
  const [timeLeft, setTimeLeft] = useState(null)
  const intl = useIntl()

  const setTime = () => {
    if (competition) {
      const elapsed = elapsedData()
      setTimeLeft(elapsed)
    }
  }

  // runs when competition is first available and/or changing
  useEffect(() => {
    setTime()
  }, [competition])

  // runs after every render
  useEffect(() => {
    const timer = setTimeout(setTime, 1000)
    return () => clearTimeout(timer)
  })

  const elapsedData = () => {
    const now = moment()
    const { startDate, active, endDate } = competition

    let diff = moment(endDate).add(1, "d").diff(now)

    if (active) {
      const duration = moment.duration(diff)
      return {
        title: "progress.remaining_time_until_end",
        date: {
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        },
      }
    } else if (now.isSameOrBefore(startDate)) {
      diff = moment(startDate).diff(now)
      const duration = moment.duration(diff)
      return {
        title: "progress.remaining_time_until_start",
        date: {
          days: Math.floor(duration.asDays()),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        },
      }
    } else if (now.isSameOrAfter(endDate)) {
      return {
        title: "progress.competition_ended",
        dateStr: moment(endDate).format("YYYY. MM. DD."),
      }
    }
  }

  return (
    !!timeLeft && (
      <div className={styles.container}>
        <span>{intl.formatMessage({ id: timeLeft.title })}</span>
        {!!timeLeft.date && (
          <div className={styles.timeContainer}>
            <div className={styles.timeUnitContainer}>
              <span className={styles.timeUnit}>{timeLeft.date.days}</span>
              <span>{intl.formatMessage({ id: "progress.day" })}</span>
            </div>
            <div className={styles.timeUnitContainer}>
              <span className={styles.timeUnit}>{timeLeft.date.hours}</span>
              <span>{intl.formatMessage({ id: "progress.hour" })}</span>
            </div>
            <div className={styles.timeUnitContainer}>
              <span className={styles.timeUnit}>{timeLeft.date.minutes}</span>
              <span>{intl.formatMessage({ id: "progress.minute" })}</span>
            </div>
            <div className={styles.timeUnitContainer}>
              <span className={styles.timeUnit}>{timeLeft.date.seconds}</span>
              <span>{intl.formatMessage({ id: "progress.second" })}</span>
            </div>
          </div>
        )}
        {!!timeLeft.dateStr && <span>{timeLeft.dateStr}</span>}
      </div>
    )
  )
}

export default TimeLeft
