import Snackbar from "@material-ui/core/Snackbar"
import React from "react"

const Modal = ({ children }) => (
  <Snackbar
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
    open={true}
    message={children}
  />
)

export default Modal
