// extracted by mini-css-extract-plugin
export var container = "Progress-module--container--1QEN4";
export var timeline = "Progress-module--timeline--24H5o";
export var circle = "Progress-module--circle--1JCIn";
export var active = "Progress-module--active--3r_Sk";
export var empty = "Progress-module--empty--1mrL-";
export var title = "Progress-module--title--3AQJq";
export var goal = "Progress-module--goal--16YTZ";
export var goalsContainer = "Progress-module--goals-container--IaMji";
export var participantContainer = "Progress-module--participant-container--zsroE";
export var participantLabel = "Progress-module--participant-label--2xx03";
export var goalLabel = "Progress-module--goal-label--1OHP8";
export var progressBarContainer = "Progress-module--progress-bar-container--3P_mZ";
export var progressBar = "Progress-module--progress-bar--1-xHM";
export var progress = "Progress-module--progress--38Cet";
export var distance = "Progress-module--distance--2HOf2";
export var timeSum = "Progress-module--time-sum--m3uE6";