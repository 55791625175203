import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import * as styles from "./GameRules.module.css"

const GameRules = ({ icon }) => {
  const intl = useIntl()

  return (
    <button
      className={styles.gameRules}
      onClick={() =>
        window.open(
          `https://www.fitpuli.hu/${intl.locale}/cyclingcompetitions.html`,
          "_blank"
        )
      }
    >
      <Img fixed={icon} />
      <span>{intl.formatMessage({ id: "game_rules" })}</span>
    </button>
  )
}

export default GameRules
