import { graphql } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import queryString from "query-string"
import React, { useEffect, useState } from "react"
import { useObjectVal } from "react-firebase-hooks/database"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/CookieConsent/CookieConsent"
import GameRules from "../components/GameRules/GameRules"
import LanguageSelector from "../components/LanguageSelector/LanguageSelector"
import Modal from "../components/Modal/Modal"
import Progress from "../components/Progress/Progress"
import Swipeable from "../components/Swipeable/Swipeable"
import TimeLeft from "../components/TimeLeft/TimeLeft"
import { CyclingGoalRank } from "../utils/CyclingGoalRank"
import useFirebase from "../utils/useFirebase"

const Index = ({ data, location }) => {
  const { url } = queryString.parse(location.search)
  const intl = useIntl()

  const compRef = useFirebase(url)

  const [goal, setGoal] = useState({
    id: "",
    title: "",
    subtitle: "",
    distance: 0,
    order: 0,
  })
  const [rawCompetition] = useObjectVal(compRef)
  const [competition, setCompetition] = useState(null)
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (!!rawCompetition) {
      setCompetition(Object.values(rawCompetition)[0])
    }
  }, [rawCompetition])

  useEffect(() => {
    async function getDataFromDatabase() {
      const cyclingGoalRank = new CyclingGoalRank(competition.distanceSum)
      const goal = cyclingGoalRank.getGoalRankForDistance()
      const progress = cyclingGoalRank.getProgress()
      setGoal({ ...goal, progress })
    }

    if (!!competition) {
      getDataFromDatabase()
    }
  }, [competition])

  const logoSources = [
    data.mobileLogo.childImageSharp.fixed,
    {
      ...data.desktopLogo.childImageSharp.fixed,
      media: `(min-width: 768px)`,
    },
  ]

  const puliSources = [
    data.mobilePuli.childImageSharp.fixed,
    {
      ...data.desktopPuli.childImageSharp.fixed,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{intl.formatMessage({ id: "html.title" })}</title>
      </Helmet>
      <>
        <section className={`${goal.id} ${!competition ? "blur" : ""}`}>
          <LanguageSelector />
          <div className="content">
            <header>
              <Img fixed={logoSources} />
              <div className="title-container">
                <Img fixed={puliSources} imgStyle={{ zIndex: 2 }} />
                <div className="title">
                  <h1>
                    {intl.formatMessage({ id: "title.first_part" })}
                    <br />
                    <span className="title-second-part">
                      {intl.formatMessage({ id: "title.second_part" })}
                    </span>
                  </h1>
                </div>
              </div>
            </header>
            <h2 className="subtitle">
              {intl.formatMessage({ id: "description" })}
            </h2>
            <TimeLeft competition={competition} />
            {goal.id && (
              <>
                <p className="goal-description">
                  {intl.formatMessage({
                    id: `${goal.id}.subtitle`,
                  })}
                </p>
                <Img fluid={data[goal.id].childImageSharp.fluid} />
              </>
            )}
            <Progress
              goal={goal}
              competition={competition}
              icons={{ participants: data.participants, biker: data.biker }}
            />
            <Swipeable
              index={index}
              setIndex={setIndex}
              distanceSum={competition?.distanceSum}
              durationSum={competition?.durationSum}
              images={{
                car: data.car.childImageSharp.fixed,
                gasoline: data.gasoline.childImageSharp.fixed,
                energy: data.energy.childImageSharp.fixed,
              }}
            />
            <GameRules icon={data.dice.childImageSharp.fixed} />
          </div>
          <CookieConsent />
        </section>
        {(rawCompetition === null || !url) && (
          <Modal>{intl.formatMessage({ id: "not_found" })}</Modal>
        )}
      </>
    </>
  )
}

export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "fitpuli_logo.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    desktopLogo: file(relativePath: { eq: "fitpuli_logo.png" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mobilePuli: file(relativePath: { eq: "puli.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    desktopPuli: file(relativePath: { eq: "puli.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    car: file(relativePath: { eq: "car.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gasoline: file(relativePath: { eq: "gasoline.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    energy: file(relativePath: { eq: "energy.png" }) {
      childImageSharp {
        fixed(width: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    okt: file(relativePath: { eq: "okt/okt.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    f1: file(relativePath: { eq: "f1/f1.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tdf: file(relativePath: { eq: "tdf/tdf.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bigwall: file(relativePath: { eq: "bigwall/bigwall.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    equator: file(relativePath: { eq: "equator/equator.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jupiter: file(relativePath: { eq: "jupiter/jupiter.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    earthmoon: file(relativePath: { eq: "earthmoon/earthmoon.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    infinite: file(relativePath: { eq: "infinite/infinite.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dice: file(relativePath: { eq: "dice.png" }) {
      childImageSharp {
        fixed(width: 22) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    biker: file(relativePath: { eq: "biker.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    participants: file(relativePath: { eq: "participants.png" }) {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Index
