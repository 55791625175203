import { useIntl, changeLocale } from "gatsby-plugin-intl"
import React, { useState } from "react"
import { languages } from "../../intl/languages"
import * as styles from "./LanguageSelector.module.css"

const LanguageSelector = () => {
  const intl = useIntl()
  const [hovered, setHovered] = useState(false)
  const onMouseEnter = e => {
    setHovered(true)
  }

  const onMouseLeave = e => {
    setHovered(false)
  }

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={styles.languageSelector}
      style={{ width: `${hovered ? 132 : 42}px` }}
    >
      {languages.map((language, index) => (
        <button
          style={{
            transform: `translateX(${hovered ? index * 46 : 0}px)`,
            zIndex: language === intl.locale ? 1100 : 1000,
            opacity: language === intl.locale ? 1 : 0.5,
          }}
          className={`${styles.languageBtn} ${styles[language]}`}
          onClick={() => changeLocale(language)}
          key={`btn_${index}`}
        />
      ))}
    </div>
  )
}

export default LanguageSelector
