import { useEffect, useState } from "react"
import getFirebase from "./firebase" // import our getFirebase function

export default function useFirebase(url) {
  const [compRef, setCompRef] = useState(null)
  const path = "/cyclingCompetitions/competitions"

  useEffect(() => {
    if (!!url) {
      setCompRef(
        getFirebase()
          .database()
          .ref(path)
          .orderByChild("url")
          .equalTo(url)
          .limitToFirst(1)
      )
    }
  }, [url])

  return compRef
}
