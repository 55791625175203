import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"
import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { useCookies } from "react-cookie"
import * as styles from "./CookieConsent.module.css"

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieconsent_status"])
  const intl = useIntl()
  const handleClose = () => setCookie("cookieconsent_status", "dismiss")

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={!cookies.cookieconsent_status}
      message={intl.formatMessage({ id: "cookie_consent.message" })}
      action={
        <div className={styles.actions}>
          <Button
            size="small"
            variant="contained"
            onClick={() =>
              window.open("https://www.fitpuli.hu/adatvedelem/", "_blank")
            }
          >
            {intl.formatMessage({ id: "cookie_consent.read" })}
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleClose}
          >
            {intl.formatMessage({ id: "cookie_consent.ok" })}
          </Button>
        </div>
      }
    />
  )
}

export default CookieConsent
