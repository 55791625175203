import Img from "gatsby-image"
import { useIntl } from "gatsby-plugin-intl"
import moment from "moment"
import React, { useState } from "react"
import { goals } from "../../utils/CyclingGoalRank"
import * as styles from "./Progress.module.css"

const ProgressBar = ({ competition, goal: actualGoal, icons, intl }) => {
  const distance = competition.distanceSum / 1000
  const goalDistance = actualGoal.distance / 1000
  const formattedDistance = new Intl.NumberFormat("hu-HU").format(
    Math.round(distance * 100) / 100
  )
  const formattedGoalDistance = new Intl.NumberFormat("hu-HU").format(
    Math.round(goalDistance * 100) / 100
  )
  const progress = actualGoal.progress <= 100 ? actualGoal.progress : 100

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.distance}>
        <Img fixed={icons.biker.childImageSharp.fixed} />
        {!!competition && (
          <span>
            <b>{formattedDistance}</b>{" "}
            {intl.formatMessage({ id: "distance.unit" })} /{" "}
            {formattedGoalDistance}{" "}
            {intl.formatMessage({ id: "distance.unit" })}
          </span>
        )}
      </div>
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>
    </div>
  )
}

const ElapsedTime = ({ competition, intl }) => {
  const durationMoment = moment.duration(competition.durationSum, "seconds")

  const date = {
    days: Math.floor(durationMoment.asDays()),
    hours: durationMoment.hours(),
    minutes: durationMoment.minutes(),
  }

  return (
    <div className={styles.timeSum}>
      <div>
        <span>{date.days}</span>{" "}
        <span>{intl.formatMessage({ id: "progress.day" })}</span>
      </div>
      <div>
        <span>{date.hours}</span>{" "}
        <span>{intl.formatMessage({ id: "progress.hour" })}</span>
      </div>
      <div>
        <span>{date.minutes}</span>{" "}
        <span>{intl.formatMessage({ id: "progress.minute" })}</span>
      </div>
    </div>
  )
}

const Goal = ({
  goal,
  index: i,
  actualGoal,
  selectedGoal,
  setSelectedGoal,
  intl,
}) => {
  const hasTitle =
    (!selectedGoal && i === actualGoal.order) ||
    (!!selectedGoal && selectedGoal.order === i)
  const isActive = i === actualGoal.order
  const isFuture = i > actualGoal.order

  const states = `${isActive ? styles.active : ""} ${
    isFuture ? styles.empty : ""
  }`

  return (
    <div className={`${styles.goal} ${states}`} key={`goal_${i}`}>
      {hasTitle && (
        <div className={styles.title}>
          {intl.formatMessage({ id: `${goal.id}.title` })}
        </div>
      )}
      <div
        className={`${styles.circle} ${states}`}
        onClick={() => i <= actualGoal.order && setSelectedGoal(goal)}
      ></div>
    </div>
  )
}

const Progress = props => {
  const { competition, goal: actualGoal, icons } = props
  const [selectedGoal, setSelectedGoal] = useState(null)
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div className={styles.goalsContainer}>
        <span className={styles.goalLabel}>
          {intl.formatMessage({ id: "progress.goals" })}
        </span>
        <div className={styles.timeline}>
          {Object.values(goals).map((goal, i) => (
            <Goal
              key={i}
              goal={goal}
              index={i}
              actualGoal={actualGoal}
              selectedGoal={selectedGoal}
              setSelectedGoal={setSelectedGoal}
              intl={intl}
            />
          ))}
        </div>
      </div>
      <div className={styles.goalsContainer}>
        <span className={styles.goalLabel}>
          {intl.formatMessage({ id: "progress.distance" })}
        </span>
        {!!competition && !!actualGoal && (
          <ProgressBar {...props} intl={intl} />
        )}
      </div>
      <div className={styles.goalsContainer}>
        <span className={styles.goalLabel}>
          {intl.formatMessage({ id: "progress.time" })}
        </span>
        {!!competition && <ElapsedTime competition={competition} intl={intl} />}
      </div>
      {!!competition && (
        <div className={styles.participantContainer}>
          <Img fixed={icons.participants.childImageSharp.fixed} />
          <span className={styles.participantLabel}>
            {competition.participantCount}{" "}
            {intl.formatMessage({ id: "progress.contestant" })}
          </span>
        </div>
      )}
    </div>
  )
}

export default Progress
