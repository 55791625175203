export const goals = {
  F1: {
    distance: 306700,
    id: "f1",
    order: 0,
  },
  OKT: {
    distance: 1128200,
    id: "okt",
    order: 1,
  },
  TDF: {
    distance: 3470000,
    id: "tdf",
    order: 2,
  },
  BIGWALL: {
    distance: 8851800,
    id: "bigwall",
    order: 3,
  },
  EQUATOR: {
    distance: 40075000,
    id: "equator",
    order: 4,
  },
  JUPITER: {
    distance: 139820000,
    id: "jupiter",
    order: 5,
  },
  EARTHMOON: {
    distance: 385000000,
    id: "earthmoon",
    order: 6,
  },
  INFINITE: {
    distance: 1000000000,
    id: "infinite",
    order: 7,
  },
}

export class CyclingGoalRank {
  constructor(distance) {
    this.distance = distance
    this.goal = this.getGoalRankForDistance()
    this.progress = this.getProgress()
  }

  getGoalRankForDistance() {
    let result = goals.INFINITE
    const arr = Object.entries(goals).reverse()
    for (var i = 0; i < arr.length; ++i) {
      const value = arr[i][1]
      if (value.distance < this.distance) {
        break
      }
      result = value
    }
    return result
  }

  getProgress() {
    const progress = (this.distance / this.goal.distance) * 100
    return Math.round((progress + Number.EPSILON) * 100) / 100
  }
}
